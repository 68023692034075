import React from 'react'
import LotBox from '../LotBox/LotBox'
import { calculateAverageDailyWeightGain } from '../../utils/Lots'
import { BoxesContainer, Container } from './styled'

const LotBottomInfo = ({ lot }) => {
  return <Container>
    <BoxesContainer>
      {
        lot.optiweighAllowed && lot.mobEntryWeight !== undefined && lot.mobEntryWeight !== 0 &&
        <LotBox type={'average'} value={`${parseFloat(lot.mobEntryWeight.toFixed(2))}kg`} measurement={'entry w/t'} tooltip={'Mob Entry Weight'} />
      }
      {
        lot.optiweighAllowed && calculateAverageDailyWeightGain(lot) !== undefined && calculateAverageDailyWeightGain(lot) !== 0 &&
        <LotBox type={'average'} value={`${parseFloat(calculateAverageDailyWeightGain(lot).toFixed(2))}kg`} measurement={'avdwtg'} tooltip={'Average Daily Weight Gain'} />
      }
      {
        lot.publicDetails.summary.weight && !lot.optiweighAllowed && lot.publicDetails.weight.average !== 0 &&
        <LotBox type={'average'} value={`${lot.publicDetails.weight.average}kg`} measurement={'ass/wt'} tooltip={'Assessment weight'} />
      }
      {
        lot.weightGainTotal !== undefined && lot.weightGainTotal !== 0 && !lot.optiweighAllowed &&
        <LotBox type={'average'} value={`${parseFloat(lot.weightGainTotal.toFixed(2))} Kg`} measurement={'wt/gain'} tooltip={'Weight Gain'} />
      }
      {
        lot.publicDetails.summary.weight && (lot.publicDetails.weight.average !== lot.publicDetails.weight.curfew || lot.optiweighAllowed) && lot.publicDetails.weight.curfew !== 0 &&
        <LotBox type={'curfew'} value={`${lot.publicDetails.weight.curfew}kg`} measurement={lot.adjustWeight === 0 && lot.optiweighAllowed ? 'live/wt' : 'cur/wt'} tooltip={lot.adjustWeight === 0 && lot.optiweighAllowed ? 'Live weight' : 'Curfew weight'} />
      }
      {
        lot.publicDetails.summary.weight && lot.publicDetails.weight.min !== 0 &&
        <LotBox value={`${lot.publicDetails.weight.min}kg`} measurement={'min'} tooltip={'Minimum weight'} />
      }
      {
        lot.publicDetails.summary.weight && lot.publicDetails.weight.max !== 0 &&
        <LotBox value={`${lot.publicDetails.weight.max}kg`} measurement={'max'} tooltip={'Maximum weight'} />
      }
      {
        lot.kindData.type === 'cattle' && lot.inlineDetails && lot.inlineDetails.euAccredited &&
        <LotBox value={'YES'} measurement={'EU Accredited'} />
      }
    </BoxesContainer>
    <div className='optiweigh-logo-wrapper'>
      {
        lot.optiweighAllowed &&
        <img src={require('../../assets/img/optiweigh_logo_landscape.png')} alt='optiweigh logo' className={'optiWeighLogo'} />
      }
    </div>
  </Container>
}

export default LotBottomInfo
